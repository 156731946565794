import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Raschin
Frameship
Being ‘enframed’, is a privilege given to special objects. The modern tendency to admit as many objects as possible to a privileged place has not yet conquered the realm of frames.`}</p>
    <p>{`This project combines this concept with the massive proliferation of image-making in digital age. The system contains an interactive surface for hanging frames. The placement and size of the frame is detected by the system and accordingly a picture is projected inside the frame. Images are chosen randomly from a selected locally stored or online gallery, or, access can be granted to other persons to post pictures inside those frames remotely using a web based interface, the way we allow friends to post pictures in social media.`}</p>
    <p>{`The story goes like this…`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=At2Tt9PJ9O0&feature=emb_logo"
      }}>{`Watch video`}</a></p>
    <p>{`To draw a rigid barrier between the domain of old fashioned frames and digital image manipulation, the interaction of user with the system is limited to placement of frames. Users can change the picture by moving and rotating and changing the placement of frames.`}</p>
    <p>{`Making prototype.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/construction.jpg",
        "alt": "construction"
      }}></img></p>
    <p>{`Apart from this considerations, the final prototype is an amusing product to be used at home and brings moments of ironic amusement.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=WNrA6KBWpEg&feature=emb_logo"
      }}>{`Watch video`}</a></p>
    <p>{`This system has a camera to detect the frames and a projector to project images inside frames. To detect the placement and orientation of the frames reacTIVision framework is used. Images are loaded either from a local image repository or from a web-based interface.`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/STRUCTURE.jpg",
        "alt": "structure"
      }}></img>{`  `}</p>
    <p>{`I collaborated with sepans in concept development and technical implementation.`}</p>
    <p>{`Frameship in ‘CCA Far away So close Exhibition Spring 2010’`}</p>
    <p><img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/EXHIBITION.jpg",
        "alt": "exhibit_1"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/EXHIBITION2.jpg",
        "alt": "exhibit_2"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/11/EXHIBITION3.jpg",
        "alt": "exhibit_2"
      }}></img></p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      